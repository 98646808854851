import { Axios } from '@/utils/Axios';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Logger } from '@/utils';
import NProgress from 'nprogress';

/**
 * @class Api Class is a fancy es6 wrapper class for axios.
 */
export class HttpClient extends Axios {
  /**
   * Creates an instance of api.
   * @param {AxiosRequestConfig} conf
   */
  constructor(conf: AxiosRequestConfig = {}) {
    super(conf);

    // https://stackoverflow.com/questions/2236747/what-is-the-use-of-the-javascript-bind-method
    this.getUri = this.getUri.bind(this);
    this.request = this.request.bind(this);
    this.get = this.get.bind(this);
    this.options = this.options.bind(this);
    this.delete = this.delete.bind(this);
    this.head = this.head.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.patch = this.patch.bind(this);

    this.interceptors.request.use((config) => {
      NProgress.start();
      Logger.axiosLog(config.method, config.url, config);

      HttpClient.adjustPerPageParameter(config);

      return config;
    });

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use(
      (response: AxiosResponse) => {
        NProgress.done();
        return response;
      },
      (error: AxiosError) => {
        NProgress.done();
        return Promise.reject(error);
      }
    );
  }

  /**
   * Get Uri
   * @param {AxiosRequestConfig} config
   * @returns {string}
   * @member Api
   */
  getUri(config?: AxiosRequestConfig): string {
    return this.getUri(config);
  }

  /**
   * generic request
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>} HTTP axios response payload.
   * @member Api
   *
   * @example
   * api.request({
   *   method: "GET|POST|DELETE|PUT|PATCH"
   *   baseUrl: "http://www.domain.com",
   *   url: "/api/v1/users",
   *   headers: {
   *     "Content-Type": "application/json"
   *  }
   * }).then((response: AxiosResponse<User>) => response.data)
   *
   *
   */
  request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.request(config);
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {AxiosRequestConfig} config  - axios request configuration.
   * @returns {Promise<R>} - HTTP `axios` response payload.
   * @member Api
   */
  get<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.get(url, config);
  }

  /**
   * HTTP OPTIONS method.
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {AxiosRequestConfig} config  - axios request configuration.
   * @returns {Promise<R>} - HTTP `axios` response payload.
   * @member Api
   */
  options<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.options(url, config);
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>}  - HTTP [axios] response payload.
   * @member Api
   */
  delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.delete(url, config);
  }

  /**
   * HTTP HEAD method.
   *
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url  - endpoint you want to reach.
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>}  - HTTP [axios] response payload.
   * @member Api
   */
  head<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.head(url, config);
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @member Api
   */
  post<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.post(url, data, config);
  }

  /**
   * HTTP PUT method.
   *
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @member Api
   */
  put<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.put(url, data, config);
  }

  /**
   * HTTP PATCH method.
   *
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {AxiosRequestConfig} config - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   */
  patch<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.patch(url, data, config);
  }

  /**
   * must be static because of axios extension
   * @param {AxiosRequestConfig} config
   * @private
   */
  private static adjustPerPageParameter(config: AxiosRequestConfig): void {
    if (config?.params?.per_page !== true && config?.params?.perPage !== true) {
      return;
    }

    const params = new URLSearchParams(window.location.search);

    config.params = {
      ...config.params,
      per_page:
        params.get('per_page') ||
        params.get('perPage') ||
        localStorage.getItem('per_page') ||
        25,
    };
  }
}
