import { env } from '@/utils';
import { Logger } from '@/utils/Logger';
import { AuthService } from '@/services/auth';
import { KeycloakService } from '@/services/auth/keycloak';
import { PassportService } from '@/services/auth/passport';

/**
 * Init the auth service.
 *
 * @returns service
 */
function initService(): AuthService {
  if (env('AUTH') === 'keycloak') {
    Logger.badge('Auth', 'keycloak');
    return new KeycloakService();
  }

  Logger.badge('Auth', 'passport');
  return new PassportService();
}

export const authService: AuthService = initService();

export * from './BaseAuthService';
export * from './AuthService';
export * from './keycloak';
export * from './passport';
