/*
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */

export default {
  de: {
    currency: { style: 'currency', currency: 'EUR' },
  },
  en: {
    currency: { style: 'currency', currency: 'USD' },
  },
  ar: {
    currency: { style: 'currency', currency: 'USD', currencyDisplay: 'code' },
  },
};
