import { get, set } from 'lodash-es';

export class Env {
  /**
   * Get the value for the given environment key.
   *
   * Throws an error if the key could not be found.
   *
   * @template K
   * @param key
   * @param fallback
   * @returns key
   */
  static getKey<K extends keyof AppVariables>(
    key: K,
    fallback?: unknown
  ): AppVariables[K] {
    let value = get(window._ENV, key, undefined);

    if (value === undefined) {
      value = get(window.typedEnv, key, undefined);
    }

    if (value === undefined) {
      value = get(process.env, key, undefined);
    }

    if (value === undefined) {
      value = fallback;
    }

    if (value === undefined) {
      throw new Error(
        `Env key not Found! The env key '${key}' could not be found!`
      );
    }

    return value;
  }

  /**
   * Set the given environment key.
   *
   * @param key
   * @param value
   * @returns
   */
  static setKey<K extends keyof AppVariables>(
    key: K,
    value: AppVariables[K]
  ): void {
    set(window._ENV, key, value);
  }

  static config<T extends Record<string, ArgInfo>>(
    info: T,
    options?: Options
  ): ArgReturnType<T> {
    const env = Env.getEnv(options);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cfg: Record<string, any> = {};

    for (const [key, argInfo] of Object.entries(info)) {
      let value = env[key];
      const envKey = key.startsWith('VITE_APP_')
        ? key.substr('VITE_APP_'.length)
        : key;

      if (value === undefined) {
        if ('default' in argInfo) {
          cfg[envKey] = argInfo.default;

          continue;
        } else {
          continue;
        }
      }

      if ('parser' in argInfo) {
        cfg[envKey] = argInfo.parser(value);

        continue;
      }

      value = value.toString();

      if (argInfo.type === 'string') {
        cfg[envKey] = value;
      } else if (argInfo.type === 'number') {
        cfg[envKey] = parseInt(value, 10);
      } else if (argInfo.type === 'list') {
        cfg[envKey] = value.split(',');
      } else if (argInfo.type === 'boolean') {
        cfg[envKey] = value.toUpperCase() === 'TRUE' || value === '1';
      }
    }

    return cfg as ArgReturnType<T>;
  }

  private static getEnv(options?: Options): Record<string, string> {
    let value = get(options, 'env', undefined);

    if (value === undefined) {
      value = get(window, '_ENV', undefined);
    }

    return Object.assign({}, import.meta.env, value);
  }
}

const env = Env.getKey;
const setEnv = Env.setKey;
const config = Env.config;

export { env, setEnv, config };
