import { env } from '@/utils/Env';

export enum LogTypeColor {
  DEFAULT = '#B8C2CC',
  INFO = '#3490DC',
  SUCCESS = '#38C172',
  WARNING = '#FFED4A',
  DANGER = '#E3342F',
}

/**
 * Wrapper class for console
 */
export class Logger {
  static con = window.console;
  static DebugMode = env('DEV', false);

  /**
   * Wrapper for console.log (same usage as console.log)
   */
  static log = Logger.typeLog(Logger.con.log);

  /**
   * Wrapper for console.error (same usage as console.error)
   */
  static error = Logger.typeLog(Logger.con.error);

  /**
   * Wrapper for console.warn (same usage as console.warn)
   */
  static warn = Logger.typeLog(Logger.con.warn);

  /**
   * Custom console.info
   *
   * @param text Can be anything (it has the type any)
   */
  static info = Logger.doLog(LogTypeColor.INFO);

  /**
   * Custom console.warn
   *
   * @param text Can be anything (it has the type any)
   */
  static warning = Logger.doLog(LogTypeColor.WARNING);

  /**
   * Custom console.error
   *
   * @param text Can be anything (it has the type any)
   */
  static danger = Logger.doLog(LogTypeColor.DANGER);

  /**
   * Logs out a badge for an axios request
   *
   * @param info The type of the axios request
   * @param data Can be anything
   */
  static axiosBadge = (info: string, data: unknown) =>
    Logger.badge('Axios', info, LogTypeColor.INFO, data);

  /**
   * Log the given axios call.
   *
   * @param {string} method
   * @param {string} url
   * @param config
   */
  static axiosLog(method: string, url: string, config: unknown): void {
    Logger.badge(method.toUpperCase(), url, LogTypeColor.INFO, config);
  }

  /**
   * Log a badge to the console.
   *
   * @param title The title of the badge
   * @param info Badge info
   * @param type The LogTypeColor
   * @param data Can be anything
   */
  static badge(
    title: string,
    info: string,
    type: LogTypeColor = LogTypeColor.DEFAULT,
    data: unknown = ''
  ): void {
    Logger.log(
      `%c ${title} %c ${info} %c`,
      'background:#35495E; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
      `background:${type}; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;`,
      'background:transparent',
      data
    );
  }

  /**
   * Takes a function from console (log, error, warn, info) and returns the function back
   * if the environment is in DebugMode
   * else it returns an empty function which takes the values but does nothing with them
   *
   * @param f A function which gets returned if the environment is in DebugMode
   * @returns A function which takes an unknown amount of values and return nothing
   */
  static typeLog(
    f: (...values: unknown[]) => void
  ): (...values: unknown[]) => void {
    return Logger.DebugMode
      ? f
      : // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_: unknown): void => {
          return;
        };
  }

  /**
   * Returns a function which logs the given text to the console using the given log type color.
   *
   * @param type The LogTypeColor in which the text should be printed
   * @returns A function with a parameter to input your text
   */
  static doLog(
    type: LogTypeColor = LogTypeColor.DEFAULT
  ): (text: unknown) => void {
    return (text: unknown): void => Logger.log(`%c${text}`, `color: ${type}`);
  }

  /**
   * Logs out a success message
   *
   * @param msg The success message to log on the console
   */
  static success(msg = 'Success'): void {
    Logger.log(
      `%c${msg}`,
      `color: green; background-color: LightGreen; padding: 3px 5px;`
    );
  }

  /**
   * Logs out a failure message
   *
   * @param msg The failure message to log on the console
   */
  static failure(msg = 'Failure'): void {
    Logger.log(
      `%c${msg}`,
      `color: #8B0000; background-color: #f94c56; padding: 3px 5px;`
    );
  }

  /**
   * Create a log entry for the given error.
   */
  // static storeLogEntry(
  //     data: any,
  //     meta?: Partial<LogEntryMeta>
  // ): void {
  //     vxm.logger.createLogEntry(data, meta);
  // }

  /**
   * Logs out a message with a given Object
   */
  static logObject(msg = 'Payload', data: Record<string, unknown>): void {
    Logger.log(`${msg}:`, data);
  }
}
