import { Logger } from '@/utils/Logger';
import { tokenService } from '@/services/storage';
import { BaseAuthService } from '../BaseAuthService';
import { getTokenValidityInSeconds } from '.';
import { env } from '@/utils';
import Keycloak from 'keycloak-js';
import { authService } from '@/services';

export class KeycloakService extends BaseAuthService {
  private keycloak: Keycloak = null;

  async init() {
    this.keycloak = new Keycloak({
      url: env('KEYCLOAK_URL'),
      realm: env('KEYCLOAK_REALM'),
      clientId: env('KEYCLOAK_CLIENT_ID'),
    });

    Logger.info('[KeycloakService] Initialising');
    const authenticated = await this.keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    });

    Logger.info(`[KeycloakService] authenticated status: ${authenticated}`);
    if (!authenticated) {
      return Promise.resolve();
    }

    Logger.info(`[KeycloakService] token has been set`);
    authService.setToken(authService.getTokenResponse());
    return Promise.resolve();
  }

  isAuthenticated(): boolean {
    return this.keycloak?.authenticated;
  }

  /**
   * Login the user with the given credentials.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  async login(): Promise<void> {
    Logger.info('[KeycloakService] Logging in');
    Logger.info(`[KeycloakService] ${typeof this.keycloak}`);

    await this.keycloak.login();
  }

  /**
   * Logout the current user by removing the tokens and also the bearer token.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  async logout(): Promise<void> {
    Logger.info('[KeycloakService] Logout');

    tokenService.removeAll();
    const to = env('KEYCLOAK_URL');
    const realm = env('KEYCLOAK_REALM', 'master');
    window.location.href = `${to}/realms/${realm}/protocol/openid-connect/logout`;
  }

  /**
   * Refresh the access token.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  async refreshToken(): Promise<void> {
    try {
      Logger.info('[KeycloakService] Refreshing token');

      Logger.info(`[KeycloakService] ${typeof this.keycloak}`);

      const refreshed = await this.keycloak.updateToken(0);

      Logger.info(
        refreshed
          ? 'Token refreshed!'
          : `Token not refreshed cause it's still valid for ${getTokenValidityInSeconds(
              this.keycloak
            )} seconds`
      );

      this.setToken(this.getTokenResponse());
    } catch (error) {
      Logger.log(error);
      await this.login();
    }
  }

  /**
   * Get the stored tokens as an token response.
   *
   * @returns token response
   */
  getTokenResponse(): TokenResponse {
    return {
      access_token: this.keycloak.token,
      refresh_token: this.keycloak.refreshToken,
      expires_in: getTokenValidityInSeconds(this.keycloak),
      token_type: 'Bearer',
    };
  }
}
