/**
 * Token service.
 *
 * Manage the access and refresh token for the current user.
 *
 * @author Sacha Steinbrink <sascha@coding-pioneers.com>
 */
export class TokenService {
  /**
   * Access token key to use for saving.
   */
  private readonly TOKEN_KEY: string = 'access_token';

  /**
   * Refresh token key to use for saving.
   */
  private readonly REFRESH_TOKEN_KEY: string = 'refresh_token';

  /**
   * Expires in key to use for saving.
   */
  private readonly EXPIRES_IN_KEY: string = 'expires_in';

  /**
   * Auth type key to use for saving.
   */
  private readonly AUTH_TYPE: string = 'auth_type';

  /**
   * has token been already stored
   * @type {Date|null}
   * @private
   */
  private tokenStorageTime = null;

  /**
   * returns if the token has been already stored
   * @returns {boolean}
   */
  get hasValidToken(): boolean {
    return new Date().getTime() >= this.tokenStorageTime;
  }

  /**
   * Get the stored tokens as an token response.
   * @returns token response
   */
  getTokenResponse(): TokenResponse {
    return {
      access_token: this.getToken(),
      refresh_token: this.getRefreshToken(),
      expires_in: this.getExpiresIn(),
      token_type: 'Bearer',
    };
  }

  /**
   * Get the access token.
   *
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   * @returns The access token or null.
   */
  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  /**
   * Get the bearer token from token service or an empty string if none exists.
   *
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   * @returns The bearer token or an empty string.
   */
  getBearerToken(): string {
    const token = this.getToken();

    if (token) {
      return `Bearer ${token}`;
    }

    return '';
  }

  /**
   * Save the given access token.
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   * @param accessToken The access token to save.
   */
  saveToken(accessToken: string): void {
    localStorage.setItem(this.TOKEN_KEY, accessToken);
    this.tokenStorageTime = new Date().getTime();
  }

  /**
   * Remove access token.
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   */
  removeToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  /**
   * Get the refresh token.
   *
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   * @returns The refresh token or null.
   */
  getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  /**
   * Save the given refresh token.
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   * @param refreshToken The refresh token to save.
   */
  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  /**
   * Remove refresh token.
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   */
  removeRefreshToken(): void {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }

  /**
   * Get the expires in.
   *
   * @returns The time that the token will live in seconds
   */
  getExpiresIn(): number {
    return Number.parseInt(localStorage.getItem(this.EXPIRES_IN_KEY), 10);
  }

  /**
   * Save the expires in
   * @param expiredAt The expired at number to save.
   */
  saveExpiresIn(expiredAt: number): void {
    localStorage.setItem(this.EXPIRES_IN_KEY, expiredAt.toString());
  }

  /**
   * Remove the expires in from local storage.
   */
  removeExpiresIn(): void {
    localStorage.removeItem(this.EXPIRES_IN_KEY);
  }

  /**
   * Get the auth type from local storage.
   *
   * @returns The auth type the token belongs to.
   */
  getAuthType(): string {
    return localStorage.getItem(this.AUTH_TYPE);
  }

  /**
   * Save the auth type to the local storage.
   * @param authType The auth type used
   */
  saveAuthType(authType: string): void {
    localStorage.setItem(this.AUTH_TYPE, authType);
  }

  /**
   * Remove the auth type from local storage.
   */
  removeAuthType(): void {
    localStorage.removeItem(this.AUTH_TYPE);
  }

  /**
   * Removes all tokens.
   * @author Sacha Steinbrink <sascha@coding-pioneers.com>
   */
  removeAll(): void {
    this.removeToken();
    this.removeRefreshToken();
    this.removeExpiresIn();
  }
}

export const tokenService = new TokenService();
