import options from '@/config/app.options';
import { LocalStorage } from '@/services/storage';

export default class LocaleInitializer {
  /**
   * Get the browser locale to detect the user's preferred locale.
   *
   * @returns browser locale
   * @param optionsLocal
   */
  static getBrowserLocale(
    optionsLocal: Dictionary<unknown>
  ): string | undefined {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...optionsLocal };

    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (!navigatorLocale) {
      return undefined;
    }

    return opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim();
  }

  /**
   * Determines whether the given locale is supported or not.
   *
   * @param locale
   * @returns true if supported
   */
  static isSupported(locale: string): boolean {
    return Object.keys(options.i18nConfig.supportedLocales).includes(locale);
  }

  /**
   * Get the starting locale for the application based on the user's
   * preferences.
   *
   * @returns starting locale
   */
  static getStartingLocale(): string {
    if (LocalStorage.get('locale')) {
      return LocalStorage.get('locale');
    }

    const browserLocale = LocaleInitializer.getBrowserLocale({
      countryCodeOnly: true,
    });

    if (browserLocale && LocaleInitializer.isSupported(browserLocale)) {
      return browserLocale;
    }

    return options.i18nConfig.defaultLocale;
  }
}
