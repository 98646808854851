import { env } from '@/utils/Env';

enum Projects {
  ArticleDescriptions = 1,
}

const adminRoleNames = ['SuperAdmin', 'Admin'];
const availableProjectSourceLanguages = {
  [Projects.ArticleDescriptions]: ['de', 'en'],
};

const i18nConfig: I18nConfig = {
  defaultLocale: env('I18N_LOCALE', 'en'),
  fallbackLocale: env('I18N_FALLBACK_LOCALE', 'en'),
  supportedLocales: {
    de: 'Deutsch',
    en: 'English',
  },
  useLocalizedLinks: env('I18N_LOCALIZED_LINKS', false),
};

const defaultHttpHeaders: Dictionary<string> = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Auth-Type': env('AUTH', 'default'),
  'Content-Type': 'application/json',
};

export default {
  i18nConfig,
  defaultHttpHeaders,
  adminRoleNames,
  availableProjectSourceLanguages,
  Projects,
};
