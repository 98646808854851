import { env } from '@/utils';
import Keycloak from 'keycloak-js';

/**
 * Gets token validity in seconds
 *
 * @returns token validity in seconds
 */
function getTokenValidityInSeconds(keycloak: Keycloak): number {
  if (!keycloak) {
    return null;
  }

  return Math.round(
    keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
  );
}

/**
 * Determine whether keycloak is enabled or not.
 *
 * @returns {boolean} Returns true if keycloak is enabled otherwise false.
 */
function isKeycloakEnabled(): boolean {
  return env('AUTH') === 'keycloak';
}

export { isKeycloakEnabled, getTokenValidityInSeconds };

export * from './KeycloakService';
