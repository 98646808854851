/**
 * Proxy Class for accessing local storage
 */
export class LocalStorage {
  /**
   * write value to local storage
   *
   * @param {string} key
   * @param {string} value
   */
  static set(key: string, value: string): LocalStorage {
    localStorage.setItem(key, value);
    return this;
  }

  /**
   * check if value exists for given key
   *
   * @param {string} key
   * @returns {boolean}
   */
  static has(key: string): boolean {
    return !!LocalStorage.get(key);
  }

  /**
   * retrieve value from local storage
   *
   * @param {string} key
   * @returns {string | null}
   */
  static get(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * removes item by key from local storage
   *
   * @param {string} key
   * @returns {LocalStorage}
   */
  static remove(key: string): LocalStorage {
    localStorage.removeItem(key);
    return this;
  }

  /**
   * clears the whole local storage
   *
   * @returns {LocalStorage}
   */
  static clear(): LocalStorage {
    localStorage.clear();
    return this;
  }
}
