import { api } from '@/api';
import { AxiosResponse } from 'axios';

export class HttpPassportService {
  /**
   * requests access token
   * @param {RequestToken} params
   * @returns {Promise<AxiosResponse<TokenResponse>>}
   */
  static token(params: RequestToken): Promise<AxiosResponse<TokenResponse>> {
    return api.post('/oauth/token', params);
  }

  /**
   * refreshes access token by refresh token
   * @param {RefreshTokenRequest} params
   * @returns {Promise<AxiosResponse<TokenResponse>>}
   */
  static refreshToken(
    params: RefreshTokenRequest
  ): Promise<AxiosResponse<TokenResponse>> {
    return api.post('/oauth/token', params);
  }
}
