import { createI18n, IntlDateTimeFormats } from 'vue-i18n';
import LocaleInitializer from '@/utils/locale/LocaleInitializer';
import options from '@/config/app.options';
import numberFormats from '@/lang/numberFormats';
import datetimeFormats from '@/lang/dateTimeFormats';

const i18n = createI18n<false>({
  // https://vue-i18n.intlify.dev/guide/advanced/composition.html#basic-usage
  legacy: false,
  locale: LocaleInitializer.getStartingLocale(),
  fallbackLocale: options.i18nConfig.fallbackLocale,
  messages: {}, // set locale messages,
  datetimeFormats: datetimeFormats as IntlDateTimeFormats,
  numberFormats,
});

export const loadedLocales: string[] = [];
export default i18n;
