import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import WordArray from 'crypto-js/lib-typedarrays';
import { env } from '@/utils';
import { tokenService } from '@/services';
import { BaseAuthService } from '../BaseAuthService';
import { HttpPassportService } from '@/services/http';
import { Logger } from '@/utils/Logger';

export class PassportService extends BaseAuthService {
  static readonly VERIFIER_KEY = 'verifier';
  static readonly STATE_KEY = 'state';

  isAuthenticated(): boolean {
    return tokenService.hasValidToken;
  }

  private static createRandomString(length: number): string {
    return [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
  }

  private static toBase64Url(value: WordArray): string {
    return value
      .toString(Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  private readonly clientId = env('PASSPORT_CLIENT_ID');
  private readonly redirectUri = `${window.location.origin}/auth`;

  set verifier(verifier: string) {
    localStorage.setItem(PassportService.VERIFIER_KEY, verifier);
  }

  get verifier(): string {
    return localStorage.getItem(PassportService.VERIFIER_KEY);
  }

  set state(state: string) {
    localStorage.setItem(PassportService.STATE_KEY, state);
  }

  get state(): string {
    return localStorage.getItem(PassportService.STATE_KEY) || '';
  }

  async onRedirect(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if ((!code || !state) && state !== this.state) {
      return;
    }

    try {
      const { data } = await HttpPassportService.token({
        grant_type: 'authorization_code',
        client_id: this.clientId,
        redirect_uri: this.redirectUri,
        code_verifier: this.verifier,
        code,
      });
      this.setToken(data);
    } catch (e) {
      Logger.log(e);
      throw e;
    }
  }

  login(): void {
    this.state = PassportService.createRandomString(40);
    this.verifier = PassportService.createRandomString(128);

    if (
      window.location.pathname !== 'auth' &&
      !window.location.search.includes('code')
    ) {
      localStorage.setItem(
        'redirect_url',
        `${window.location.pathname}${window.location.search}`
      );
    }

    const challenge = PassportService.toBase64Url(sha256(this.verifier));
    window.location.href = this.buildLoginUrl(challenge);
  }

  async logout(): Promise<void> {
    tokenService.removeAll();
    window.location.href = `${env('API_URL')}/web/logout`;
  }

  async refreshToken(): Promise<void> {
    try {
      const { data } = await HttpPassportService.refreshToken({
        grant_type: 'refresh_token',
        client_id: this.clientId,
        refresh_token: tokenService.getRefreshToken(),
      });
      this.setToken(data);
    } catch (e) {
      Logger.log(e);
      throw e;
    }
  }

  getTokenResponse(): TokenResponse {
    return tokenService.getTokenResponse();
  }

  private buildLoginUrl(challenge: string): string {
    return `${env('API_URL')}/oauth/authorize?client_id=${
      this.clientId
    }&redirect_uri=${this.redirectUri}&response_type=code&scope=*&state=${
      this.state
    }&code_challenge=${challenge}&code_challenge_method=S256`;
  }
}
